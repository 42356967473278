import React, { useState } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby-plugin-intl"

// Hooks
import useInterval from "../hooks/useInterval"
import useTranslation from "../hooks/useTranslation"
import useResponsive from "../hooks/useResponsive"

// Icons
import PuzzleIcon from "../assets/icons/puzzle.svg"

// Logo
import WooLogo from "../assets/logos/woo.svg"
import ShopifyLogo from "../assets/logos/shopify.svg"
// import MagentoLogo from "../assets/logos/magento.svg"
import PrestashopLogo from "../assets/logos/prestashop.svg"
import OpencartLogo from "../assets/logos/opencart.svg"
import OscommerceLogo from "../assets/logos/oscommerce.png"
import WhmcsLogo from "../assets/logos/whmcs.svg"

// Components
import SectionTitle from "../components/section-title"
import TabletDevice from "../components/tablet-device"
import PhoneDevice from "../components/phone-device"

// Styles
import "../styles/components/modules-section.scss"

const ModulesSection = ({ imageFluids }) => {
  const { t } = useTranslation()
  const logos = [
    {
      icon: WooLogo,
      alt: "Woo Commerce",
    },
    {
      icon: ShopifyLogo,
      alt: "Shopify",
    },
    // {
    //   icon: MagentoLogo,
    //   alt: "Magento",
    // },
    {
      icon: PrestashopLogo,
      alt: "Presta Shop",
    },
    {
      icon: OpencartLogo,
      alt: "Open Cart",
    },
    {
      icon: OscommerceLogo,
      alt: "Oscommerce",
    },
    {
      icon: WhmcsLogo,
      alt: "WHMCS",
    },
  ]
  const [visibleLogos, setVisibleLogos] = useState([0, 1, 2])
  useInterval(() => {
    setVisibleLogos([
      (visibleLogos[0] + 3) % logos.length,
      (visibleLogos[1] + 3) % logos.length,
      (visibleLogos[2] + 3) % logos.length,
    ])
  }, 3000)
  const { isMobile } = useResponsive()
  return (
    <section className="section modules-section has-margin-bottom-8 has-margin-bottom-5-mobile">
      <div className="container">
        <SectionTitle
          title={t("Already Built-In")}
          subtitle={t(
            "Twispay provides an extended array of payment module integrations for top-rated ecommerce platforms"
          )}
          subtitleWidth={600}
        />
        <div className="container">
          <div className="columns">
            <div className="column is-one-third">
              <div className="icon is-larger has-text-primary has-margin-bottom-6">
                <PuzzleIcon />
              </div>
              <h4 className="title is-4 is-size-5-mobile">
                {t("Easy to Integrate Plugins")}
              </h4>
              <p className="has-text-secondary is-size-5 is-size-6-mobile has-margin-bottom-5">
                {t(
                  "Add credit card payments to your website using our customizable modules integrated with the most popular ecommerce platforms"
                )}
              </p>
              <div className="logos-wrapper has-margin-bottom-5">
                {visibleLogos.map(logoIndex => (
                  <div className="logo-wrapper" key={logos[logoIndex].alt}>
                    <motion.img
                      initial={{ x: 30, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      src={logos[logoIndex].icon}
                      alt={logos[logoIndex].alt}
                    />
                  </div>
                ))}
              </div>
              <Link to="/payment-modules" className="button is-soft-white">
                {t("See all Modules")}
              </Link>
            </div>
            <div className="column">
              {!isMobile && (
                <TabletDevice
                  className="is-perspective"
                  bgFluid={imageFluids[0]}
                />
              )}
              <PhoneDevice
                className={`is-perspective is-phone-device ${
                  isMobile ? "" : "is-not-mobile"
                }`}
                bgFluid={imageFluids[1]}
                isLeft={!isMobile}
                isCenter={isMobile}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ModulesSection
