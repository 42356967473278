import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

import "../styles/components/tablet-device.scss"

const TabletDevice = ({ children, isLeft, isCenter, bgFluid, ...props }) => {
  return (
    <div {...props}>
      <div
        className={`tablet-device-wrapper ${isLeft ? "is-left" : ""} ${
          isCenter ? "is-center" : ""
        }`}
      >
        {bgFluid ? (
          <BackgroundImage
            Tag="div"
            style={{
              backgroundPosition: "left",
            }}
            className="display-wrapper"
            fluid={bgFluid}
          />
        ) : (
          <div className="display-wrapper" style={{ position: "relative" }}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

TabletDevice.propTypes = {
  children: PropTypes.node,
  isPerspective: PropTypes.bool,
  isLeft: PropTypes.bool,
  bgFluid: PropTypes.object,
}

export default TabletDevice
