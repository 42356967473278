import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"
import useResponsive from "../hooks/useResponsive"

// Icons
import FireIcon from "../assets/icons/fire.svg"
import BubblesIcon from "../assets/icons/bubbles.svg"
import SafeCheckIcon from "../assets/icons/safe-check.svg"
import GrowIcon from "../assets/icons/grow.svg"
import NoteIcon from "../assets/icons/note.svg"

// Components
import SectionTitle from "../components/section-title"
import GetStartedSection from "../components/get-started-section"
import FeaturesSection from "../components/features-section"
import TabletDevice from "../components/tablet-device"
import PhoneDevice from "../components/phone-device"
import ModulesSection from "../components/modules-section"

// Styles
import "../styles/pages/payment-service-provider-for-ecommerce.scss"

const HeroSection = ({ imagesFluid }) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  return (
    <section className="section is-hero has-margin-bottom-6">
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <h1 className="title main-title has-text-white is-spaced">
              {t("Payment Processing for eCommerce")}
            </h1>
            <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
              {t(
                "Twispay is a FinTech with an eMoney license that provides more than a payment gateway. Gain access to an extensive set of eCommerce payment processing tools enabling eShops to accept payments online and fully personalise the checkout process."
              )}
            </h2>
          </div>
          <div className="column">
            {!isMobile && (
              <TabletDevice
                className="is-perspective"
                bgFluid={imagesFluid[0]}
              />
            )}
            <PhoneDevice
              className={`is-perspective is-phone-device ${
                isMobile ? "" : "is-not-mobile"
              }`}
              bgFluid={imagesFluid[1]}
              isLeft={!isMobile}
              isCenter={isMobile}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
const DesignedSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <FireIcon />,
      title: t("Increased Conversion"),
      description: t(
        "A seamless checkout process is essential to digital success"
      ),
    },
    {
      icon: <BubblesIcon />,
      title: t("Focused on User Experience"),
      description: t(
        "Your checkout page must match the look and feel of your brand"
      ),
    },
    {
      icon: <SafeCheckIcon />,
      title: t("World-Class Security"),
      description: t(
        "Customers need to feel completely safe to provide their payment details"
      ),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Designed to Boost Sales")}
          subtitle={t(
            "Visitors become customers on the checkout page. This is where it all gets real, and your value proposition turns into money."
          )}
        />
        <div className="columns  has-text-centered ">
          {items.map(item => (
            <div key={item.title} className="column">
              <span className="icon is-larger has-margin-bottom-5 has-text-primary">
                {item.icon}
              </span>
              <h4 className="title is-4 ">{item.title}</h4>
              <p className="subtitle is-5 has-text-secondary">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

// const ModulesSections = () => {
//   const intl = useIntl()
//   const useCommaFormat = intl.locale === "ro" || intl.locale === "it"
//   const { t } = useTranslation()
//   const [activeModule, setActiveModule] = useState("magento")
//   const modules = {
//     magento: {
//       logo: MagentoLogo,
//       title: "Magento",
//       description: t(
//         "Twispay integrates seamlessly with Magento, one of the most popular e-commerce platforms, featuring swift integration and support for both Magento 1 and 2."
//       ),
//       percentage: useCommaFormat ? "24,6" : "24.6",
//       height: "90%",
//     },
//     woocommerce: {
//       logo: WooLogo,
//       title: "WooCommerce",
//       description: t(
//         "Twispay grants the ability to instantly sell your products online using the most popular eCommerce platform for WordPress, which now integrates closely with our payment gateway."
//       ),
//       percentage: useCommaFormat ? "19,8" : "19.8",
//       height: "80%",
//     },
//     opencart: {
//       logo: OpencartLogo,
//       title: "OpenCart",
//       description: t(
//         "Getting your OpenCart customers to purchase through your open source platform could not be easier than integrating Twispay's sleek payment plugin."
//       ),
//       percentage: useCommaFormat ? "7,4" : "7.4",
//       height: "33%",
//     },
//     prestashop: {
//       logo: PrestaShopLogo,
//       title: "PrestaShop",
//       description: t(
//         "Start accepting online credit card payments in your PrestaShop online store. Twispay is quick and easy to integrate and, for the user, completing purchases is a breeze."
//       ),
//       percentage: useCommaFormat ? "6.4" : "6,4",
//       height: "30%",
//     },
//     oscommerce: {
//       logo: OscommerceLogo,
//       title: "OsCommerce",
//       description: t(
//         "Reliable online payments for OsCommerce; a robust, easy to install and configure e-commerce payment module to get you set and processing cards in minutes."
//       ),
//       percentage: useCommaFormat ? "5,8" : "5.8",
//       height: "27%",
//     },
//   }

//   return (
//     <section className="section has-margin-bottom-6 modules-section">
//       <div className="container">
//         <SectionTitle
//           title={t("Free eCommerce Payment Modules")}
//           subtitle={t(
//             "Twispay offers an extended array of payment modules for the most popular ecommerce platforms"
//           )}
//         />
//         <div className="columns is-vcentered">
//           <div className="column is-one-third">
//             <AnimatePresence exitBeforeEnter>
//               <motion.div
//                 key={activeModule}
//                 initial={{ x: 10, opacity: 0 }}
//                 animate={{ x: 0, opacity: 1 }}
//                 exit={{ x: -10, opacity: 0 }}
//                 style={{ minHeight: 310 }}
//               >
//                 <img
//                   src={modules[activeModule].logo}
//                   width="200"
//                   className="has-margin-bottom-5"
//                   alt={`${modules[activeModule].title} Logo`}
//                 />
//                 <h4 className="title  is-4">{modules[activeModule].title}</h4>
//                 <p className="subtitle has-text-secondary has-margin-bottom-6">
//                   {modules[activeModule].description}
//                 </p>
//               </motion.div>
//             </AnimatePresence>

//             <Link
//               to="/price-calculator"
//               className="button is-success is-medium "
//             >
//               {t("Get Started")}
//             </Link>
//           </div>
//           <div className="column">
//             <Chart
//               bars={modules}
//               activeBar={activeModule}
//               setActiveBar={setActiveModule}
//             >
//               {Object.keys(modules).map(moduleKey => (
//                 <button
//                   key={moduleKey}
//                   className={`module-logo-wrapper is-flex is-vcentered is-styleless ${
//                     moduleKey === activeModule ? "active" : ""
//                   }`}
//                   onClick={() =>
//                     setActiveModule ? setActiveModule(moduleKey) : null
//                   }
//                 >
//                   <img
//                     src={modules[moduleKey].logo}
//                     alt={`${modules[activeModule].title} Logo`}
//                   />
//                 </button>
//               ))}
//             </Chart>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }
const ShopSection = ({ imageFluid }) => {
  const { isMobile } = useResponsive()
  const { t } = useTranslation()
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("We Practice What We Preach")}
          subtitle={t(
            "To the best of our knowledge, Twispay is the first and only payment service provider to open an eShop"
          )}
        />
        <div className="columns">
          <div className="column">
            <TabletDevice
              className={`is-perspective ${
                isMobile ? " has-margin-bottom-6" : ""
              }`}
              bgFluid={imageFluid}
            />
          </div>
          <div className="column is-one-third">
            <h4 className="is-size-4">
              <a
                href="https://shop.twispay.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("The Twispay Shop")}
              </a>
            </h4>
            <p className="is-size-5 has-text-secondary has-margin-bottom-4">
              {t(
                "Fully grasping the ins and outs of running an online business must be an essential strategic endeavour for any company aiming to provide cutting-edge solutions in the eCommerce field."
              )}
            </p>
            <p className="is-size-5 has-text-secondary has-margin-bottom-4">
              {t(
                "So, we've decided to go through what you're going through. Every step of the way!"
              )}
            </p>
            <p className="is-size-5 has-text-secondary">
              {t(
                "As a European Payment Service Provider, Twispay fashioned a fully fledged playground to test out the latest trends in eCommerce."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
const DashboardSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <GrowIcon />,
      title: t("Key Performance Indicators"),
      subtitle: t("One place to stay on top of all your merchant data"),
    },
    {
      icon: <BubblesIcon />,
      title: t("Real-Time Overview"),
      subtitle: t("Keep those stats climbing, and watch your business grow"),
    },

    {
      icon: <NoteIcon />,
      title: t("Meaningful Reporting"),
      subtitle: t("Your accountant would probably choose Twispay"),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Insightful Transaction Dashboard")}
          subtitle={t(
            "Staying in charge of your finances is as critical as making sure your website runs properly"
          )}
        />
        <div className="columns">
          <div className="column is-one-third">
            {items.map(item => (
              <div className="is-flex has-margin-bottom-6">
                <span className="icon is-medium has-text-primary has-margin-right-5">
                  {item.icon}
                </span>
                <div>
                  <h4 className="title is-5">{item.title}</h4>
                  <div className="subtitle is-6 has-text-secondary">
                    {item.subtitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="column">
            <TabletDevice
              className="is-perspective"
              bgFluid={imageFluid}
              isCenter
            />
          </div>
        </div>
      </div>
    </section>
  )
}
const ECommercePage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-blue-violet"
      pageName="payment-service-provider-for-ecommerce"
    >
      <SEO title={t("Payment Processing for eCommerce")} />
      <HeroSection
        imagesFluid={[
          images.buildinTablet.childImageSharp.fluid,
          images.buildinPhone.childImageSharp.fluid,
        ]}
      />
      <DesignedSection />
      <FeaturesSection
        title={t("Fully Customizable")}
        subtitle={t(
          "Quick and easy to integrate, Twispay dissolves seamlessly into your website's flows and designs"
        )}
        images={images}
        invertImages
      />
      <ModulesSection
        imageFluids={[
          images.buildinTablet.childImageSharp.fluid,
          images.buildinPhone.childImageSharp.fluid,
        ]}
      />
      <ShopSection imageFluid={images.shopImage.childImageSharp.fluid} />
      <DashboardSection
        imageFluid={images.dashboardImage.childImageSharp.fluid}
      />
      <GetStartedSection
        title={t("Not Just a Payment Service Provider")}
        subtitle={t("Fine-tune your online shopping experience with Twispay")}
      />
    </Layout>
  )
}

export const images = graphql`
  query {
    buildinTablet: file(relativePath: { eq: "home-builtin-tablet.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    buildinPhone: file(relativePath: { eq: "home-builtin-phone.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secureImage: file(relativePath: { eq: "payment-modules-secure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutImage: file(relativePath: { eq: "checkout.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutDarkImage: file(relativePath: { eq: "checkout_dark.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shopImage: file(relativePath: { eq: "ecommerce-shop.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    dashboardImage: file(relativePath: { eq: "ecommerce-dashboard.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    buildinTablet: file(relativePath: { eq: "home-builtin-tablet.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    buildinPhone: file(relativePath: { eq: "home-builtin-phone.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ECommercePage
